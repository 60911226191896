@mixin for-phone-only {
	@media (max-width: 599px) { @content; }
}

@font-face {
	font-family: 'montserratmedium';
	src: '/assets/fonts/montserrat-medium-webfont.woff2' format('woff2'),
			 '/assets/fonts/montserrat-medium-webfont.woff' format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'montserratbold';
	src: '/assets/fonts/montserrat-bold-webfont.woff2' format('woff2'),
			 '/assets/fonts/montserrat-bold-webfont.woff' format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'montserratlight';
	src: '/assets/fonts/montserrat-light-webfont.woff2' format('woff2'),
			 '/assets/fonts/montserrat-light-webfont.woff' format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'signikaregular';
	src: '/assets/fonts/signika-regular-webfont.woff2' format('woff2'),
			 '/assets/fonts/signika-regular-webfont.woff' format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'signikasemibold';
	src: '/assets/fonts/signika-semibold-webfont.woff2' format('woff2'),
			 '/assets/fonts/signika-semibold-webfont.woff' format('woff');
	font-weight: normal;
	font-style: normal;
}

.statistics {
	display: flex;
	justify-content: space-between;
	margin: 40px 0px 80px 0px;
   
	@include for-phone-only {
	 flex-direction: column;
	 margin-bottom: 0;
	}
   
	&__bar-chart {
	 height: 200px;
	 min-width: 400px;
   
	 @include for-phone-only {
	  min-width: 80vw;
	 }
	}
   
	.recharts-text, .recharts-tooltip-wrapper {
	 font-family: 'signikaregular';
	}
   }
   
   .statistics-stat {
	padding: 20px;
	border: 1px solid grey;
	text-align: center;
	font-family: 'signikaregular';
	width: 40%;
	height: 70%;
	display: flex;
	   flex-direction: column;
	   align-items: center;
	   justify-content: center;
	margin-left: auto;
   
	@include for-phone-only {
	 width: auto;
	 height: 150px;
	 margin-left: 0;
	}
   
	&:nth-of-type(1) {
	 width: auto;
	 margin-left: 0;
	}
   
	&__wrap {
	 display: flex;
	 justify-content: space-around;
	 flex-grow: 1;
   
	 @include for-phone-only {
	  flex-direction: column;
	  margin-top: 25px;
	 }
   
	 .statistics-stat {
	  &:nth-of-type(1) {
	   margin-left: auto;
	   width: 40%;
   
	   @include for-phone-only {
		margin: 20px 0;
		width: auto;
	   }
	  }
	 }
	}
   
   
	&__data {
	 font-size: 1.6rem;
	}
	&__label {
	 font-size: 1.2rem;
	}
   }


   .brandCard {
	height: 100%;
	max-width: 219px;
	width: 100%;
	min-height: 190px;
	display: flex;
	flex-direction: column;
	text-align: center;
	text-decoration: none;
	background-color: #ffffff;
	box-shadow: 0px 3px 6px rgba(197, 208, 223, 0.5);
	border-radius: 6px;
	margin: 0;
	padding: 12px;
	overflow: hidden;
	cursor: pointer;
  
	&__container {
	  display: flex;
	  flex-direction: column;
	  min-height: 118px;
	  height: 100%;
	  position: relative;
	  border-top: none;
	  background-color: #fff;
	  overflow: hidden;
	  padding: 0 6px;
	}
  
	&__content {
	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: center;
	  align-items: flex-start;
	  margin-top: auto;
	}
  
	&__background {
	  overflow: hidden;
	  width: 84px;
	  height: 60px;
	  background-size: contain;
	  background-repeat: no-repeat;
	  background-position: center;
	  margin: 0 auto 12px;
	}
  
	&__image {
	  width: 46px;
	  height: 43px;
	  margin-right: 4px;
	  overflow: hidden;
	  flex-basis: 46px;
	  flex-grow: 0;
	  flex-shrink: 0;
	  background-repeat: no-repeat;
	  background-position: center;
	  background-size: contain;
	}
  
	&__info {
	  flex-grow: 0;
	  flex-shrink: 1;
	  flex-basis: auto;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  text-align: left;
	  justify-content: flex-start;
	}
  
	&__type {
	  font-weight: normal;
	  font-size: 12px;
	  line-height: 16px;
	  letter-spacing: -0.03em;
	  color: #121318;
	  margin: 3px 0 2px;
	}

  
	&__tabs {
	  margin-bottom: 6px;
  
	//   @include tablet-large {
	// 	margin-bottom: 4px;
	//   }
	}
  
	&__availableIn {
	  white-space: nowrap;
	  font-size: 12px;
	  line-height: 16px;
	  letter-spacing: -0.02em;
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;
  
	  span {
		display: inline;
	  }
  
	//   &-title {
	// 	color: $opacity-text-color;
	// 	margin-right: 10px;
	//   }
  
	  &-text {
		font-weight: 600;
	  }
  
	//   @include tablet-large {
	// 	font-size: 14px;
	// 	line-height: 19px;
	//   }
	}
  
	&__name {
	  font-weight: 600;
	  font-size: 14px;
	  line-height: 19px;
	  text-align: center;
	  letter-spacing: -0.03em;
	  color: #000000;
	  margin: 0 0 16px;
	}
  
	&__text {
	  font-weight: normal;
	  font-size: 12px;
	  line-height: 14px;
	  letter-spacing: -0.3px;
	  color: #ccc;
	  margin: 0 0 auto;
	  height: 100%;
	}
  
	ul {
	  list-style-type: none;
	  margin: 0;
	  padding: 0;
	}
  
	&.subscription {
	  display: inline-flex;
	  flex-direction: column;
	  width: 219px;
	  height: 167px;
	  padding: 12px 12px 20px;
	  white-space: normal;
  
	  .brandCard {
		padding: 15px 24px 32px;
  
		&__name {
		  height: 57px;
		  margin: 0 0 auto;
		  padding: 0 24px;
  
		  span {
			display: inline-block;
			vertical-align: middle;
		  }
  
		  &:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 0;
			height: 100%;
		  }
		}
  
		&__background {
		  width: 148px;
		  height: 49px;
		}
	  }
	}
  
	&.stats {
	  display: block;
	  width: 100%;
	//   max-width: 100%;
	//   height: 228px;
	  text-align: left;
	  margin: 0;
	  padding: 24px;
  
	//   @include mobile-xxl {
	// 	max-width: 219px;
	//   }
  
	  .brandCard {
		&__name {
		  font-size: 10px;
		  line-height: 14px;
		  letter-spacing: 0.04em;
		  text-align: left;
		  text-transform: uppercase;
		  color: #ccc;
		}
  
		&__row {
		  display: flex;
		  justify-content: space-between;
		  margin: 0 0 10px;
  
		  &:last-child {
			margin: 0;
		  }
		}
  
		&__key,
		&__percentage {
		  font-weight: normal;
		  font-size: 14px;
		  line-height: 19px;
		  letter-spacing: -0.03em;
		  color: #000000;
		}
  
		&__key {
		  position: relative;
  
		  &:before {
			content: "";
			position: absolute;
			top: 8px;
			left: -9px;
			display: block;
			width: 4px;
			height: 4px;
			background-color: hotpink;
			border-radius: 50%;
			z-index: 1;
		  }
		}
	  }
	}
  }
