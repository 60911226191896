@import "style/statistics.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiAppBar-colorSecondary {
  background-color: #161f6e !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ra-field.ra-field-100{
  width: 100%;
  float: left;
}
.ra-field.ra-field-50{
  width: 50%;
  float: left;
}
.ra-field.ra-field-33{
  width: 33%;
  float: left;
}
.ra-field.ra-field-clear{
  clear:both;
}

.thumbNailView img {
  max-width: 100px;
  max-height: auto;
}